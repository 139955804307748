<template lang="pug">
#product-detail
	.d-flex.justify-content-center.my-5(v-if="loading")
		b-spinner(variant="okcarpremium", label="Loading...")
	b-row.justify-content-center.my-2(v-else)
		b-col(cols="12")
			b-breadcrumb(:items="breadcrumb")
		b-col(v-if="product.vehicles_products", cols="12", md="9", lg="7")
			.card(v-bind:class="{ sold: product.sold }")
				Ribbon(:sold="product.sold" :title="product.label_name" :color="product.label_color" :top="70" :size="1.4")
				.card-header
					b-row
						b-col.text-left(v-if="product.user", cols="7")
							.float-left.mr-2
								i(v-if="!product.user.avatar").icon-user-circle.fa-2x
								b-avatar(v-else :src="getAvatar(product.user.avatar)" size="2rem" :title="product.user.name")
							.p-1 {{ product.user.name }}
						b-col.text-right(cols="5")
							star-rating(:star-size="20")
				v-carousel#carousel-fade(:video="product.video" :images="images")
				.card-body.pt-3
					price(:price="product.price.toString()" :funded_price="product.vehicles_products.funded_price.toString()")
					.card-subtitle.mt-3(v-text="product.vehicles_products.brand.name+' '+product.vehicles_products.modelo.name+' '+product.vehicles_products.version+' '+product.vehicles_products.year")
					.card-text.product-detail-vehicle.my-3(v-if='product.vehicles_products')
						features(:features="product.vehicles_products")
						detail(:id="product.id" :vehicle="product.vehicles_products")
						accesories(v-bind:accessories="product.vehicles_products.accessories" v-if="product.vehicles_products.accessories")
						.description(v-if="product.description")
							p {{ product.description }}
				.card-footer.text-muted
					.d-none.d-sm-block
						contact(:payment_type="product.vehicles_products.payment_type" :message="messageWsp" :phone="product.user.phone")
	#action-contact.row.justify-content-center.d-block.d-sm-none(v-if="product.vehicles_products")
		contact(:payment_type="product.vehicles_products.payment_type" :message="messageWsp" :phone="product.user.phone")
</template>
<script>
	import StarRating from "vue-star-rating"
	import { mapMutations, mapState } from "vuex"
	import Features from "./Features"
	import Accesories from "./Accesories"
	import Contact from "./Contact"
	import Ribbon from "./Ribbon"
	import Price from "./Price"
	import Detail from "./Detail"
	import VCarousel from "../helpers/v-carousel"

	export default {
		name:"DetailedProduct",
		components: {
			StarRating,
			Accesories,
			Features,
			Contact,
			Ribbon,
			Price,
			Detail,
			VCarousel
		},
		metaInfo() {
			return {
				title: "okcarpremium",
				titleTemplate: '%s | '+ this.product.title,
				meta: [
					{
						vmid: "description",
						name: "description",
						content: `${this.product.description.substr(0,116)}...`
					},
					{
						vmid: "og:title",
						name: "og:title",
						content: `okcarpremium | ${this.product.title}`
					},
					{
						vmid: "og:description",
						name: "og:description",
						content: `${this.product.description.substr(0,116)}...`
					},
					{
						vmid: "og:image",
						name: "og:image",
						content: this.getImage()
					}
				]
			}
		},
		data() {
			return {
				name: "",
				product: {
					title: "",
					description: "",
					images: [
						{
							name:''
						}
					]
				},
				images: [],
				breadcrumb: [],
				accessories:[],
				messageWsp: "Hola, acabo de ver tu vehículo en okcarpremium.1 ¿Aun estará disponible?"
			};
		},
		computed: {
			...mapState(['loading']),
			currentUrl() {
				return window.location.href;
			}
		},
		mounted() {
			this.getProduct()
		},
		methods: {
			...mapMutations(['SHOW_LOADING','HIDE_LOADING']),
			checkImages() {
				if(this.product.user.role === 1)
					this.images = this.product.images.splice(0,10)
				else
					this.images = this.product.images
			},
			getNameUser(name) {
				const userName = name.toString().replace(/\./g, "").trim();
				let fullname = userName.split(" ");
				if(fullname[1])
					return fullname[0].charAt(0)+fullname[1].charAt(0);
				else
					return userName.substring(0,2)
			},
			getAvatar(avatar){
				return `${this.$axios.defaults.baseURL}/images/profile/${avatar}`
			},
			getImage(){
				if(this.images[0])
					return this.images[0].name
				else
					return 'https://api.okcarpremium.laserenaweb.cl/images/no_image_thumb.jpg'
			},
			async getProduct() {
				try {
					this.SHOW_LOADING()
					const {data:{data}} =  await this.$axios.get(`/api/v1/product/${this.$route.params.slug}/${this.$route.params.hashid}`)
					this.product = data
					this.product.vehicles_products.accessories = JSON.parse(this.product.vehicles_products.accessories)
					this.checkImages()
					this.setBreadcrumb(this.product)
				}
				catch (e) {
					console.error(e);
				}
				finally {
					this.HIDE_LOADING();
				}
			},
			setBreadcrumb (product) {
				this.breadcrumb = [
					{
						text: "Inicio",
						to: { name: 'inicio' }
					},
					{
						text: `Carroceria ${product.vehicles_products.bodywork.name}`,
						to: {name: 'filters', query: { bodywork:product.vehicles_products.bodywork.slug }}
					},
					{
						text: product.title,
						active: true
					}
				]
			}
		}
	}
</script>
<style lang="scss">
	@import "@/assets/scss/_variables";

	#product-detail {
		margin-bottom: 50px !important;
		overflow-x:hidden;
		.breadcrumb{
			@media (max-width: 576px) {
				margin-top: 1.5rem!important;
			}
		}
		.card {
			margin-bottom: 60px;
			&.sold{
				.img-fluid{
					filter: grayscale(1);
				}
				&::before{
					background-color: #000;
					border-radius: 10px;
					color:white;
					content:'VENDIDO';
					font-size: 3rem;
					font-weight: 500;
					left: 0;
					margin: 0 auto;
					padding:5px 40px;
					position:absolute;
					right: 0;
					text-align: center;
					top: 15%;
					transform: rotate(-35deg);
					width: 340px;
					z-index:2;
					@media (max-width: 576px) {
						top: 12%;
						font-size: 2.2rem;
						width:300px;
					}
				}
			}
			&-title {
				color: #212529;
				font-size: 1.5rem;
				font-weight: 700;
				margin-bottom: 10px;
				width: auto;
			}
			&-header,
			&-footer{
				background-color: white;
			}
			&-subtitle {
				font-size: 1.6rem;
				margin: 5px 0;
				@media (max-width: 576px) {
					font-size: 1.4rem;
				}
			}
			.product-detail-vehicle {
				.phone-user{
					background: $primary;
					border-radius: 50%;
					color: white;
					height: 30px;
					margin-left: 10px;
					margin-top: -5px;
					padding: 7px;
					vertical-align: middle;
					width: 30px;
				}
				.icon-detail-user{
					background: $primary;
					border-radius: 50%;
					color: white;
					height: 30px;
					padding: 7px;
					vertical-align: middle;
					width: 30px;
				}
			}
		}
		.description{
			border-top: 1px solid #e5e5e5;
			margin-top: 10px;
			padding-top: 15px;
		}
	}

	.vue-star-rating {
		margin-left: auto;
		margin-right: 0;
	}
	.badge-90 {
		font-size: 90%;
	}
	#action-contact{
		display: block;
		overflow: hidden;
		text-align: center;
		position: fixed;
		background-color: white;
		box-shadow: -5px 0 18px rgba(0, 0, 0, .1);
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 100;
		padding: 10px 30px;
		.footer-action {
			padding: 10px 20px;
		}
	}
</style>