<template lang="pug">
  router-link.btn.btn-outline-okcarpremium.btn-block.font-weight-bold(
    :to="{name: 'credits', params: { slug: this.$route.params.slug, hashid: this.$route.params.hashid }}"
  )
    font-awesome-icon(icon="wallet")
    span.ml-1 Simula tu financiamiento
</template>
<script>
export default {
  name:"VCredit"
}
</script>