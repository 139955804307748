<template lang="pug">
b-carousel#carousel-fade(
  style="text-shadow: 0px 0px 2px #000"
  fade
  controls
  indicators
  img-width="1024"
  img-height="480"
  :interval="interval"
)
  b-carousel-slide(v-if="video")
    template(v-slot:img)
      v-youtube(:video-id="video" :player-option="playerOption")
  b-carousel-slide(v-for="image in images" :key="image.id" :img-src="image.name")
</template>
<script>
import VYoutube from "./v-youtube"

export default {
  name:"VCarousel",
  components: {
    VYoutube
  },
  props:{
    video:{
      type:String,
      default:''
    },
    images:{
      type:Array,
      default: () => {
        return [];
      }
    }
  },
  data(){
    return {
      playerOption:{
        rel:0,
        autoplay:1,
        controls:0,
        start:1,
        mute:0
      },
      interval: 5000
    }
  },
  mounted() {
    this.interval = 0; // Set the interval variable to zero to pause the carousel
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/_variables";
.carousel-indicators{
  bottom:-41px;
  li{
    background-color: #333;
    width: 20px;
    &.active {
      background-color: $primary;
    }
  }
}
/* .carousel-control-next, .carousel-control-prev {
  display: none;
} */
</style>