<template lang="pug">
  b-button.call.font-weight-bold(block variant="outline-okcarpremium" @click.stop="getCall()")
    font-awesome-icon(icon="phone-alt")
    span.ml-1 Llamar
</template>
<script>
export default {
  name:"VCall",
  props:{
    phone:{
      type:Number,
      default:0
    }
  },
  methods:{
    getCall(){
      window.open(`tel:+56${this.phone}`,'_blank');
    },
  }
}
</script>