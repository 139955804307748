<template lang="pug">
  b-button.btn-whatsapp.font-weight-bold(block variant="outline-okcarpremium" @click.stop="getWsp()")
    font-awesome-icon(:icon="['fab','whatsapp']")
    span.ml-1 WhatsApp
</template>
<script>
export default {
  name:"VWhatsapp",
  props:{
    phone:{
      type:Number,
      default:0
    },
    message:{
      type:String,
      default:'Hola'
    }
  },
  computed:{
    encodeMessage: function(){
      return encodeURIComponent(this.message)
    }
  },
  methods:{
    getWsp(){
      window.open(`https://wa.me/+56${this.phone}?text=${this.encodeMessage}`,'_blank');
    }
  }
}
</script>